<template>
  <v-container>
    <h1 class="mt-0 mb-2">{{ $metaInfo.title }}</h1>
    <v-alert v-if="syncPending && !withServiceWorker" type="warning">
      Не&nbsp;уходите со&nbsp;страницы и&nbsp;не&nbsp;закрывайте&nbsp;ее, пока
      процесс не&nbsp;завершится.
    </v-alert>
    <v-alert v-if="syncPending && withServiceWorker" type="info">
      Не&nbsp;закрывайте браузер пока процесс не&nbsp;завершится.
    </v-alert>
    <v-progress-linear
      v-if="syncPending"
      :value="progress"
      height="20"
      class="mt-4"
      striped
      color="primary"
    >
      {{ progress }}%
    </v-progress-linear>
    <v-sheet class="pa-4">
      <v-row>
        <v-col md="6">
          <validation-observer
            ref="form"
            tag="form"
            class="d-flex flex-column mt-1"
            @submit.prevent="handleSubmit"
          >
            <validation-provider v-slot="{ errors }" slim rules="required">
              <v-select
                v-model="select"
                :items="providers"
                item-text="title"
                item-value="slug"
                label="Select"
                :error-messages="errors"
                :disabled="syncPending || filePending"
                return-object
                single-line
                outlined
                attach
                dense
              >
                <template #selection="{ item }">
                  <img
                    width="24px"
                    :src="item.icon"
                    :alt="item.title"
                    class="mr-2"
                  />
                  <span>
                    {{ item.title }}
                  </span>
                </template>
                <template #item="{ item: itm }">
                  <img
                    width="24px"
                    :src="itm.icon"
                    :alt="itm.title"
                    class="mr-2"
                  />
                  <span>
                    {{ itm.title }}
                  </span>
                </template></v-select
              >
            </validation-provider>
            <validation-provider v-slot="{ errors }" slim rules="required">
              <v-file-input
                v-model="file"
                outlined
                dense
                show-size
                truncate-length="15"
                class="icon-right mr-2"
                accept=".csv"
                :disabled="syncPending || filePending"
                :error-messages="errors"
                append-icon="mdi-paperclip"
                :prepend-icon="''"
              ></v-file-input>
            </validation-provider>
            <div class="d-flex align-center flex-wrap">
              <v-btn
                color="primary"
                type="submit"
                :loading="syncPending || filePending"
                :disabled="syncPending || filePending"
                class="align-self-start mr-2"
                >Отправить</v-btn
              >
              <p v-if="pendingMessage" class="ma-0">{{ pendingMessage }}</p>
            </div>
          </validation-observer>
        </v-col>
        <v-col md="6">
          <p>
            Вы&nbsp;можете загрузить таблицу с&nbsp;идентификаторами
            пользователей для синхронизации данных социальных сетей. Указанные
            идентификаторы будут обработаны, баллы будут пересчитаны.
          </p>

          <p class="mb-0"><strong>Требования к файлу</strong></p>
          <ul>
            <li>Файл формата <strong>.CSV</strong></li>
            <li>В&nbsp;таблице должна быть <strong>один</strong> столбец</li>
            <li>
              Столбец &mdash; <strong>Talent ID</strong> пользователя (число).
            </li>
            <li>
              Максимальное количество строк таблицы: <strong>10000</strong>
            </li>
          </ul>
          <a href="/files/sync_social_example.csv" download>Пример файла</a>
        </v-col>
      </v-row>

      <v-alert
        v-if="parseFileErrors.length"
        class="mt-4"
        elevation="2"
        border="left"
        colored-border
        color="error"
      >
        <p class="mb-1 black--text text-subtitle-1">
          В&nbsp;файле обнаружены ошибки
        </p>
        <v-btn
          color="error"
          small
          @click="openErrorModal('Ошибки при заполнении файла')"
          >Смотреть ошибки</v-btn
        >
      </v-alert>

      <v-alert
        v-if="showFinishedAlert"
        elevation="2"
        class="mt-4"
        border="left"
        colored-border
        :color="syncErrorText ? 'info' : 'success'"
      >
        <p class="success--text text-subtitle-1 mb-1">{{ finishedText }}</p>
        <div v-if="showSyncErrorText">
          <p class="mb-2 black--text text-subtitle-1">
            {{ syncErrorText }}
          </p>
          <v-btn
            color="error"
            small
            @click="openErrorModal('Ошибки при синхронизации')"
            >Смотреть ошибки</v-btn
          >
        </div>
      </v-alert>
      <div v-if="error" class="error--text my-3">{{ error }}</div>
    </v-sheet>
    <sync-error-dialog
      v-model="errorDialog"
      :parse-file-errors="parseFileErrors"
      :sync-errors="syncErrors"
      :title="errorDialogTitle"
    />
  </v-container>
</template>
<script>
import SyncErrorDialog from "@/components/dialogs/SyncErrorDialog.vue";
import {
  SOCIAL_PROVIDERS,
  PROVIDERS_FOR_SYNC,
  ACCESS_TOKEN,
  SYNC_CHUNK_SIZE,
} from "@/constants";
import { numCases } from "@/utils";
import { talentClient } from "@/api";
import {
  chunkArray,
  parseAndValidateFile,
  sendMessageToServiceWorker,
} from "@/utils/sync";
import Cookies from "js-cookie";

const PROVIDERS = Object.values(SOCIAL_PROVIDERS).filter(
  (provider) => PROVIDERS_FOR_SYNC[provider.slug]
);

const initialState = () => {
  return {
    file: null,
    filePending: false,
    syncPending: false,
    error: "",
    syncErrors: {},
    select: PROVIDERS[0],
    parseFileErrors: [],
    errorDialog: false,
    errorDialogTitle: "Ошибки при синхронизации",
    progress: 0,
    syncFinished: false,
    usersCount: 0,
    withServiceWorker: false,
    finishedText: "",
  };
};

export default {
  name: "SocialSyncPage",
  components: { SyncErrorDialog },

  metaInfo() {
    return {
      title: "Синхронизация соцсетей",
    };
  },
  data() {
    return {
      ...initialState(),
    };
  },
  computed: {
    showFinishedAlert() {
      return this.syncFinished && (this.finishedText || this.showSyncErrorText);
    },
    showSyncErrorText() {
      return this.syncErrorText && !this.syncPending && !this.filePending;
    },
    providers() {
      return Object.values(SOCIAL_PROVIDERS).filter(
        (provider) => PROVIDERS_FOR_SYNC[provider.slug]
      );
    },
    pendingMessage() {
      if (this.syncPending) return "Синхронизация...";
      if (this.filePending) return "Разбираем файл...";
      return "";
    },
    syncErrorsCount() {
      return Object.keys(this.syncErrors).length;
    },
    syncErrorText() {
      if (!this.syncErrorsCount) return;
      return `Не удалось создать задачу для ${this.syncErrorsCount} ${numCases(
        ["пользователя", "пользователей", "пользователей"],
        this.syncErrorsCount
      )}`;
    },
  },
  watch: {
    file: {
      handler() {
        this.reset();
      },
    },
    select: {
      handler() {
        this.reset();
      },
    },
  },
  created() {
    this.PROVIDERS = PROVIDERS;
  },
  mounted() {
    if ("serviceWorker" in navigator) {
      this.serviceWorkerInit();
    }
  },
  beforeDestroy() {
    if ("serviceWorker" in navigator) {
      navigator.serviceWorker.removeEventListener(
        "message",
        this.handleServiceWorkerMessage
      );
    }
  },
  methods: {
    async sWSubmit() {
      const token = Cookies.get(ACCESS_TOKEN);
      try {
        const payload = {
          file: this.file,
          token,
          provider: this.select.slug,
        };
        await sendMessageToServiceWorker({ type: "START_SYNC", payload });
      } catch (error) {
        console.log("Ошибка при отправке сообщения в Service Worker:", error);
        this.withServiceWorker = false;
        this.baseSubmit();
      }
    },
    async baseSubmit() {
      const { file } = this;
      try {
        const { ids, errors: parseErrors } = await parseAndValidateFile({
          file,
          callback: this.readFileAsText,
        });
        if (parseErrors.length > 0) {
          this.parseFileErrors = parseErrors;
          this.filePending = false;
          return;
        }
        this.filePending = false;
        this.usersCount = ids.length;
        this.progress = 0;
        this.syncPending = true;
        const chunks = chunkArray(ids, SYNC_CHUNK_SIZE);
        let processedCount = 0;
        for (const chunk of chunks) {
          const promises = chunk.map((userId) =>
            this.userSyncSocialAuth(userId, this.select.slug)
          );

          const results = await Promise.allSettled(promises);

          results.forEach((result, index) => {
            const userId = chunk[index];
            if (result.status === "rejected") {
              const message =
                result.reason.message || "Ошибка запуска синхронизации";
              const status = `${
                result.reason.status
                  ? `Код ошибки: ${result.reason.status}. `
                  : ""
              }`;

              this.syncErrors = {
                ...this.syncErrors,
                [userId]: status + message,
              };
            }
          });

          processedCount += chunk.length;
          this.progress = Math.round((processedCount / ids.length) * 100);
        }

        this.setFinishedText();
        this.syncFinished = true;
      } catch (error) {
        console.log("error", error);
        this.error = error?.message || "Ошибка при синхронизации";
      }
      this.syncPending = false;
    },
    setFinishedText() {
      if (this.usersCount) {
        this.finishedText = `Задача по синхронизации ${
          this.select.title
        } создана для ${this.usersCount} ${numCases(
          ["пользователя", "пользователей", "пользователей"],
          this.usersCount
        )}`;
      }
    },
    async handleSubmit() {
      if (this.syncPending || this.filePending) return;
      this.syncFinished = false;
      this.usersCount = 0;
      this.finishedText = "";
      const isValid = await this.$refs.form.validate();
      if (!isValid) return;
      this.filePending = true;
      this.error = "";
      this.parseFileErrors = [];
      this.syncErrors = {};
      if (this.withServiceWorker) {
        this.sWSubmit();
      } else {
        this.baseSubmit();
      }
    },
    readFileAsText(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = (event) => resolve(event.target.result);
        reader.onerror = () => reject(new Error("Ошибка чтения файла"));
        reader.readAsText(file);
      });
    },
    openErrorModal(title) {
      this.errorDialogTitle = title;
      this.errorDialog = true;
    },
    async userSyncSocialAuth(id, slug) {
      await talentClient({
        method: "POST",
        url: `users/${id}/social-auths/sync/${slug}/`,
      });
    },
    reset() {
      this.parseFileErrors = [];
      this.syncErrors = {};
      this.usersCount = 0;
      this.syncFinished = false;
      this.finishedText = "";
    },
    serviceWorkerInit() {
      navigator.serviceWorker.ready
        .then((registration) => {
          if (registration.active) {
            navigator.serviceWorker.addEventListener(
              "message",
              this.handleServiceWorkerMessage
            );
            registration.active.postMessage({
              type: "GET_SYNC_STATE",
            });
            this.withServiceWorker = true;
          }
        })
        .catch((err) =>
          console.log("Ошибка при работе с Service Worker:", err)
        );
    },
    handleServiceWorkerMessage(event) {
      const { type, payload } = event.data;

      switch (type) {
        case "SYNC_STATE":
          Object.keys(initialState()).forEach((key) => {
            if (key in payload) {
              this[key] = payload[key];
            }
          });
          break;
        case "SYNC_PROGRESS":
          this.progress = payload;
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-right.v-input {
  margin-right: 0 !important;
}
</style>
