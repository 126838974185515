<template>
  <info-section
    class="mb-4"
    :total="events.total"
    :pending="events.pending"
    icon="mdi-calendar-multiple"
    title="Мероприятия"
    :pages="events.pagesCount"
    :current-page="events.page"
    @setPage="setPage"
  >
    <event-card
      v-for="(event, idx) in events.list"
      :key="event.id"
      :event="event"
      class="item"
      :class="{ last: idx === events.list.length - 1 }"
    />
    <div v-if="!events.list.length && !events.pending && isOrganization">
      Нет данных
    </div></info-section
  >
</template>
<script>
import InfoSection from "@/components/InfoSection.vue";
import EventCard from "@/components/organizations/EventCard.vue";
export default {
  name: "EventsSection",
  components: { InfoSection, EventCard },
  props: {
    events: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isOrganization() {
      return !!this.$store.state.organizations.organization;
    },
  },
  methods: {
    setPage(page) {
      this.$emit("setEventsPage", page);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  border-bottom: 1px solid #c0c0c0;
  padding-bottom: 10px;
  margin-bottom: 10px;
  transition: background-color 0.3s;

  &.last {
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
</style>
