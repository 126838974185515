<template>
  <div class="d-flex justify-space-between flex-column-reverse flex-sm-row">
    <div class="mr-0 mr-sm-2">
      <div class="mb-2">
        <strong>{{ event.title || event.alt_title || event.id }}</strong>
      </div>
      <div>
        <v-chip
          v-if="eventTimeText"
          color="info"
          small
          outlined
          class="mr-1 mb-1"
          >{{ eventTimeText }}</v-chip
        >
        <v-chip v-if="!isPublic" color="error" small class="mr-1 mb-1"
          >Непубличное</v-chip
        >
        <v-chip v-if="isDraft" color="info" small class="mr-1 mb-1"
          >Черновик</v-chip
        >
        <v-chip v-if="isCanceled" color="warning" small class="mr-1 mb-1"
          >Отменено</v-chip
        >
      </div>
      <option-row title="ID мероприятия:"
        ><talent-link :path="`admin/package/event/${event.id}/change/`">{{
          event.id
        }}</talent-link></option-row
      >
      <option-row title="Формат:">{{
        event.format === "online" ? "Онлайн" : "Оффлайн"
      }}</option-row>
      <option-row v-if="startDate" title="Дата начала:">{{
        startDate
      }}</option-row>
      <option-row v-if="endDate" title="Дата окончания:">{{
        endDate
      }}</option-row>
      <option-row v-if="expectedStartDate" title="Ожидаемая дата начала:">{{
        expectedStartDate
      }}</option-row>
      <option-row v-if="dateEndRequest" title="Крайний срок подачи заявки:">{{
        dateEndRequest
      }}</option-row>
      <option-row
        v-if="dateEndUpdate"
        title="Крайний срок обновления заявки:"
        >{{ dateEndUpdate }}</option-row
      >
      <option-row title="Количество заявок:">{{
        event.requests_count ?? 0
      }}</option-row>
      <option-row title="Количество достижений:">{{
        event.achievements_count ?? 0
      }}</option-row>

      <option-row v-if="createdAt" title="Создано:">{{ createdAt }}</option-row>
      <option-row v-if="updatedAt" title="Обновлено:">{{
        updatedAt
      }}</option-row>
      <option-row v-if="showLink" title="Посмотреть в календаре:"
        ><a
          :href="calendarLink"
          target="_blank"
          rel="noopener noreferrer"
          class="link"
          >смотреть</a
        ></option-row
      >
    </div>
    <div v-if="event.cover">
      <v-img
        max-height="150"
        max-width="300"
        :src="event.cover"
        class="mb-2 mb-sm-0"
      />
    </div>
  </div>
</template>
<script>
import dayjs from "@/plugins/dayjs";
import TalentLink from "@/components/TalentLink.vue";

export default {
  name: "EventCard",
  components: {
    TalentLink,
  },
  props: {
    event: {
      type: Object,
      required: true,
    },
  },
  computed: {
    startDate() {
      if (!this.event.start) return "";
      return dayjs(this.event.start).format("LLL");
    },
    endDate() {
      if (!this.event.end) return "";
      return dayjs(this.event.end).format("LLL");
    },
    expectedStartDate() {
      if (!this.event.expected_start) return "";
      return dayjs(this.event.expected_start).format("LLL");
    },
    createdAt() {
      if (!this.event.created_at) return "";
      return dayjs(this.event.created_at).format("LLL");
    },
    updatedAt() {
      if (!this.event.updated_at) return "";
      return dayjs(this.event.updated_at).format("LLL");
    },
    dateEndUpdate() {
      if (!this.event.limits?.date_end_update) return "";
      return dayjs(this.event.limits.date_end_update).format("LLL");
    },
    dateEndRequest() {
      if (!this.event.limits?.date_end_request) return "";
      return dayjs(this.event.limits.date_end_request).format("LLL");
    },
    calendarLink() {
      return `${process.env.VUE_APP_TALENT_BASE}/events/${this.event.id}`;
    },
    isPublic() {
      return this.event.public;
    },
    isDraft() {
      return this.event.draft;
    },
    isCanceled() {
      return this.event.canceled;
    },
    showLink() {
      return this.isPublic && !this.isDraft;
    },
    isEventEnd() {
      if (!this.event.end) return false;
      return dayjs().isAfter(dayjs(this.event.end));
    },
    isEventStartBefore() {
      return !this.event.start || dayjs(this.event.start).diff(dayjs()) <= 0;
    },
    eventTimeText() {
      if (!(this.isPublic && !this.isDraft && !this.isCanceled)) {
        return null;
      }
      if (this.isEventEnd) {
        return "Мероприятие завершено";
      } else if (this.isEventStartBefore) {
        return "Мероприятие проводится";
      } else {
        return "Мероприятие еще не началось";
      }
    },
  },
};
</script>
