<template>
  <div>
    <div class="d-flex align-center mb-4">
      <v-avatar
        color="#dedede"
        class="member-card__avatar"
        darken
        size="40"
        elevation="1"
      >
        <img v-if="avatar" :src="avatar" :alt="fullName" />
        <v-icon v-else color="white" size="24">mdi-account</v-icon>
      </v-avatar>
      <div class="d-flex align-center ml-2">
        <div>
          <p class="title">
            {{ fullName || "#" + admin.id }}
          </p>
          <div class="body-2">ID наставника: {{ admin.id }}</div>
        </div>
      </div>
    </div>

    <option-row title="Администратор:">{{
      admin.is_owner ? "да" : "нет"
    }}</option-row>

    <option-row v-if="createDate" title="Создан:">{{ createDate }}</option-row>
    <option-row v-if="updateDate" title="Обновлен:">{{
      updateDate
    }}</option-row>
    <option-row v-if="admin?.user" title="Talent ID:"
      ><a
        href="#"
        class="link"
        @click.stop.prevent="openDialog(admin.user.id)"
        >{{ admin.user.id }}</a
      ></option-row
    >
    <p class="secondary--text text--lighten-2 mb-1">
      Ученики:
      <span v-if="pending"
        ><v-progress-circular
          size="18"
          width="2"
          indeterminate
          color="primary"
        ></v-progress-circular> </span
      ><span v-if="participants && !pending" class="black--text">{{
        participants.length
      }}</span>
    </p>
    <div v-if="!!participants" class="box">
      <div v-for="participant in participants" :key="participant.uniqueKey">
        <a
          href="#"
          class="link"
          @click.stop.prevent="openDialog(participant.userId)"
        >
          <span class="mb-1">
            {{ participant.fullName || participant.userId || participant.id }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import { pathJoin } from "@/api/utils";
import { DATE_DATABASE_FORMAT, DATE_DISPLAY_FORMAT } from "@/constants";
import { talentClient } from "@/api";
import dayjs from "@/plugins/dayjs";
const LIMIT = 20;
export default {
  name: "AdminCard",
  props: {
    admin: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      participants: null,
      pending: false,
    };
  },
  computed: {
    avatar() {
      const {
        admin: { user },
      } = this;
      if (!user?.avatar) return "";
      if (user.avatar.startsWith("https")) {
        return user.avatar;
      }
      return pathJoin(process.env.VUE_APP_AVATAR_S3, user.avatar);
    },
    organization() {
      return this.$store.state.organizations.organization;
    },
    fullName() {
      const { admin } = this;
      if (!admin.user) return "";
      return [
        admin.user.last_name,
        admin.user.first_name,
        admin.user.middle_name,
      ]
        .filter(Boolean)
        .join(" ");
    },
    createDate() {
      if (!this.admin?.created_at) return;
      return dayjs(this.admin.created_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
    updateDate() {
      if (!this.admin?.updated_at) return;
      return dayjs(this.admin.updated_at, DATE_DATABASE_FORMAT).format(
        DATE_DISPLAY_FORMAT
      );
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (val?.id) {
          this.getParticipants();
        }
      },
      immediate: true,
    },
  },
  activated() {
    if (this.organization) {
      this.getParticipants();
    }
  },
  methods: {
    openDialog(userId) {
      if (userId) {
        this.$emit("openDialog", userId);
      }
    },
    getFullName(user) {
      if (!user) return "";
      return [user.last_name, user.first_name, user.middle_name]
        .filter(Boolean)
        .join(" ");
    },
    async getParticipants() {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      const mentorId = this.admin?.user?.id;
      if (this.pending || !adminId || !mentorId) return;
      this.pending = true;
      let results = [];
      try {
        const firstResponse = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.$route.params.orgId}/member/`,
          params: {
            limit: LIMIT,
            offset: 0,
            mentor: mentorId,
          },
        });

        results = [...firstResponse.data.results];
        if (firstResponse.data.count > LIMIT) {
          const pages = Math.ceil(firstResponse.data.count / LIMIT);
          const requests = Array.from({ length: pages - 1 }, (_, idx) => {
            const page = idx + 1;
            return talentClient({
              method: "GET",
              url: `users/${adminId}/organizations/${this.$route.params.orgId}/member/`,
              params: {
                limit: LIMIT,
                offset: page * LIMIT,
                mentor: mentorId,
              },
            });
          });
          const responses = await Promise.all(requests);
          const list = responses.reduce((acc, response) => {
            return [...acc, ...response.data.results];
          }, []);
          results = [...results, ...list];
        }
      } catch (error) {
        console.log("error", error);
      }

      this.participants = results.map((item, idx) => {
        return {
          ...item,
          userId: item?.user?.id,
          fullName: this.getFullName(item?.user),
          uniqueKey: `${item?.user?.id}-${idx}-${item.id}`,
        };
      });
      this.pending = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.title {
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  color: inherit !important;
  transition: color 0.3s;
  max-width: 600px;
  margin-bottom: 0;
}

.box {
  max-height: 200px;
  overflow-y: auto;
}
</style>
