<template>
  <v-container>
    <div class="d-md-flex d-sm-block">
      <div class="mr-md-4 mr-xs-0">
        <div class="aside">
          <sub-page-routes :routes="ORGANIZATION_ROUTES" />
        </div>
      </div>

      <div class="flex-grow-1">
        <div v-if="error" class="error--text">
          {{ error }}
        </div>
        <keep-alive :max="MAX_ROUTES_CACHE">
          <router-view :key="$route.fullPath" />
        </keep-alive>
      </div>
    </div>
  </v-container>
</template>
<script>
import SubPageRoutes from "@/components/SubPageRoutes.vue";
import { ORGANIZATION_ROUTES } from "@/constants/index";
export default {
  name: "OrganizationView",
  components: {
    SubPageRoutes,
  },
  data() {
    return {
      error: "",
    };
  },
  computed: {
    ORGANIZATION_ROUTES() {
      return ORGANIZATION_ROUTES;
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (
          to?.params?.orgId !== from?.params?.orgId &&
          to?.name !== "organization"
        ) {
          this.$store.commit("organizations/SET_ORGANIZATION", null);
          this.init();
        }
      },
      immediate: true,
    },
  },
  created() {
    this.MAX_ROUTES_CACHE = 10;
  },
  beforeDestroy() {
    this.$store.commit("organizations/SET_ORGANIZATION", null);
  },
  methods: {
    init() {
      this.getOrganization();
    },
    async getOrganization() {
      try {
        this.error = "";
        await this.$store.dispatch(
          "organizations/getOrganization",
          this.$route.params.orgId
        );
      } catch (error) {
        console.log("error", error);
        this.error = error;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.aside {
  position: sticky;
  top: 60px;
  z-index: 1;
  width: 100%;
  max-width: 200px;
  @media screen and (max-width: 959px) {
    max-width: 100%;
    margin-bottom: 20px;
    position: static;
  }
}
</style>
