<template>
  <events-section :events="events" @setEventsPage="getEvents" />
</template>
<script>
import { talentClient } from "@/api";
import { initialListingModel } from "@/utils";
import EventsSection from "@/components/organizations/EventsSection.vue";

export default {
  name: "OrganizationEventsPage",
  components: { EventsSection },
  data() {
    return {
      events: initialListingModel(5),
    };
  },
  computed: {
    organization() {
      return this.$store.state.organizations.organization;
    },
  },
  watch: {
    organization: {
      handler(val) {
        if (!val) return;
        this.getEvents(this.events.page);
      },
    },
  },
  activated() {
    if (this.organization) {
      this.getEvents(this.events.page);
    }
  },
  methods: {
    async getEvents(page = 1) {
      const adminId = this.organization?.admin_users?.[0]?.user_id;
      if (this.events.pending || !adminId) return;
      this.events.error = "";
      this.events.pending = true;
      this.events.page = page;
      try {
        const { data } = await talentClient({
          method: "GET",
          url: `users/${adminId}/organizations/${this.organization.id}/events/`,
          params: {
            limit: this.events.limit,
            offset: (this.events.page - 1) * this.events.limit,
          },
        });
        this.events.total = data.count;
        this.events.list = data.results;
        this.events.pagesCount = Math.ceil(data.count / this.events.limit);
      } catch (error) {
        this.events.error = error.message;
      }
      this.events.pending = false;
    },
  },
};
</script>
